import * as React from "react"
import { graphql } from "gatsby"
import MainPage from "../widgets/MainPage"
import "swiper/css"
import "../css/magnific-popup.css"
import "../css/_mixin.less"
import "../css/_global.less"
import "../css/_hardik.less"
import "../css/_influencers.less"
import "../css/_elements.less"
import "../css/_responsive.less"
import { Router } from "@reach/router"
import About from "./about-us"
import CaseStudies from "./case-studies"
import CaseStudy from "./case-studies/{ContentfulCaseStudy.slug}"
import TermsAndConditions from "./terms-and-conditions"
import PrivacyPolicy from "./privacy-policy"
import Influencers from "./influencers"
import Blog from "./blog/{contentfulBlogPost.slug}"
import BlogPosts from "./blog"
import { addUtmTagsInUrls, setCookieFromUrl } from "../widgets/cookie"

interface HomepageProps {
  data: any
}
//changes in blog page
export default function Homepage(props: HomepageProps) {
  //pass utm from cookie if any url is clicked

  React.useEffect(() => {
    setCookieFromUrl()
  }, [1])
  React.useEffect(() => {
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])
  return (
    <Router>
      <MainPage path="/" {...props} />
      <About path="/about-us" {...props} />
      <CaseStudies path="/case-studies" {...props} />
      <CaseStudy path="/case-study/{ContentfulCaseStudy.slug}" {...props} />
      <TermsAndConditions path="/terms-and-conditions" {...props} />
      <PrivacyPolicy path="/privacy-policy" {...props} />
      <Influencers path="/influencers" {...props} />
      <Blog path="/blog" {...props} />
      <BlogPosts path="/blog/{ContentfulBlogPost.slug}" {...props} />
    </Router>
  )
}
export const query = graphql`
  {
    allContentfulCaseStudies {
      nodes {
        summary {
          id
          key
          value
          sub
        }
      }
    }
  }
`
